import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Section, Container, Content } from "./index";

const About: React.FC = () => {
  const {
    dataJson: { basics },
  } = useStaticQuery<{ dataJson: { basics: { summary: string } } }>(graphql`
    query AboutData {
      dataJson {
        basics {
          summary
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <h3>About</h3>

        <Content>
          <p>{basics.summary}</p>
        </Content>
      </Container>
    </Section>
  );
};

export default About;
