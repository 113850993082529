import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Section, Container, Content, List, Link } from "./index";
import ProfileIcon from "./ProfileIcon";

const Profiles: React.FC = () => {
  const {
    dataJson: { basics },
  } = useStaticQuery<{
    dataJson: {
      basics: {
        profiles: Array<{ network: string; username: string; url: string }>;
      };
    };
  }>(graphql`
    query ProfilesData {
      dataJson {
        basics {
          profiles {
            network
            username
            url
          }
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <h3>Profiles</h3>

        <Content>
          <List inLine>
            {basics.profiles.map(({ network, username, url }) => (
              <li key={network}>
                <Link href={url}>
                  <ProfileIcon type={network} />
                  <span>{username}</span>
                </Link>
              </li>
            ))}
          </List>
        </Content>
      </Container>
    </Section>
  );
};

export default Profiles;
