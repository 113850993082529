import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Section, Container, Content } from "./index";

const References: React.FC = () => {
  const {
    dataJson: { references },
  } = useStaticQuery<{ dataJson: { references: string } }>(graphql`
    query ReferencesData {
      dataJson {
        references
      }
    }
  `);

  return (
    <Section>
      <Container>
        <h3>References</h3>
        <Content>{references}</Content>
      </Container>
    </Section>
  );
};

export default References;
