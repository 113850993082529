import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Section, Container, Content, Link } from "./index";

const Contact: React.FC = () => {
  const {
    dataJson: { basics },
  } = useStaticQuery<{
    dataJson: {
      basics: {
        email: string;
        website: string;
        location: Array<{ city: string; countryCode: string }>;
      };
    };
  }>(graphql`
    query ContactData {
      dataJson {
        basics {
          email
          website
          location {
            city
            countryCode
          }
        }
      }
    }
  `);

  const multipleLocations = basics.location.length > 1;

  return (
    <Section>
      <Container>
        <h3>Contact</h3>

        <Content>
          <dl>
            <dt>Email</dt>
            <dd>
              <Link href={`mailto:${basics.email}`}>{basics.email}</Link>
            </dd>

            <dt>Website</dt>
            <dd>
              <Link href={basics.website}>{basics.website}</Link>
            </dd>

            <dt>Location{multipleLocations ? "s" : ""}</dt>
            <dl>
              {basics.location.map(({ city, countryCode }) => (
                <dd>
                  <Link
                    href={`https://www.google.com/maps/place/${city}`}
                    target="_blank"
                  >
                    {city}, {countryCode}
                  </Link>
                </dd>
              ))}
            </dl>
          </dl>
        </Content>
      </Container>
    </Section>
  );
};

export default Contact;
