import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Section, Container, Content, List, EmploymentHeading } from "./index";
import Tenure from "./Tenure";

const Employment: React.FC = () => {
  const {
    dataJson: { work },
  } = useStaticQuery<{
    dataJson: {
      work: Array<{
        company: string;
        position: string;
        website: string;
        startDate: string;
        endDate: string;
        summary: string;
        highlights: Array<string>;
      }>;
    };
  }>(graphql`
    query EmploymentData {
      dataJson {
        work {
          company
          position
          website
          startDate
          endDate
          summary
          highlights
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <h3>Employment</h3>

        <Content>
          <List>
            {work.map(
              ({
                company,
                position,
                startDate,
                endDate,
                summary,
                highlights,
              }) => (
                <Item key={startDate}>
                  <EmploymentHeading>
                    <h4>
                      {position}
                      <br />
                      <Company>{company}</Company>
                    </h4>
                    <Tenure startDate={startDate} endDate={endDate} />
                  </EmploymentHeading>
                  <Summary>{summary}</Summary>
                  {highlights?.length > 0 ? (
                    <>
                      <h5>Highlights</h5>
                      <ul>
                        {highlights.map((hl) => (
                          <li key={hl}>{hl}</li>
                        ))}
                      </ul>
                    </>
                  ) : null}
                </Item>
              )
            )}
          </List>
        </Content>
      </Container>
    </Section>
  );
};

const Item = styled.li`
  margin-bottom: 20px;
`;
const Company = styled.span`
  color: #4c566a;
`;
const Summary = styled.p`
  margin-bottom: 10px !important;
`;

export default Employment;
