import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { format } from "date-fns/fp";

import { Section, Container, Content, List } from "./index";

const Education: React.FC = () => {
  const {
    dataJson: { education },
  } = useStaticQuery<{
    dataJson: {
      education: Array<{
        institution: string;
        area: string;
        studyType: string;
        endDate: string;
        classification: string;
      }>;
    };
  }>(graphql`
    query EducationData {
      dataJson {
        education {
          institution
          area
          studyType
          endDate
          classification
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <h3>Education</h3>

        <Content>
          <List>
            {education.map(
              ({ institution, area, studyType, endDate, classification }) =>
                `${format(
                  "yyy",
                  Date.parse(endDate)
                )} ${institution} ${studyType} ${area} ${classification}`
            )}
          </List>
        </Content>
      </Container>
    </Section>
  );
};

export default Education;
