import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Contact from "../components/Contact";
import About from "../components/About";
import Profiles from "../components/Profiles";
import Employment from "../components/Employment";
import Education from "../components/Education";
import Skills from "../components/Skills";
import Interests from "../components/Interests";
import References from "../components/References";

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Home" />

    <Contact />
    <About />
    <Profiles />
    <Employment />
    <Education />
    <Skills />
    <Interests />
    <References />
  </Layout>
);

export default IndexPage;
