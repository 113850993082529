import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Section, Container, Content, List } from "./index";

const Interests: React.FC = () => {
  const {
    dataJson: { interests },
  } = useStaticQuery<{
    dataJson: { interests: Array<{ name: string; summary: string }> };
  }>(graphql`
    query InterestsData {
      dataJson {
        interests {
          name
          summary
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <h3>Interests</h3>

        <Content>
          <List>
            {interests.map(({ name, summary }) => (
              <li key={name}>
                <h4>{name}</h4>
                <p>{summary}</p>
              </li>
            ))}
          </List>
        </Content>
      </Container>
    </Section>
  );
};

export default Interests;
