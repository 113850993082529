import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Section, Container, Content, List } from "./index";

const Skills: React.FC = () => {
  const {
    dataJson: { skills },
  } = useStaticQuery<{
    dataJson: { skills: Array<{ name: string; keywords: Array<string> }> };
  }>(graphql`
    query SkillsData {
      dataJson {
        skills {
          name
          keywords
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <h3>Skills</h3>

        <Content>
          <List inLine>
            {skills.map(({ name, keywords }) => (
              <li key={name}>
                <h4>{name}</h4>
                <ul>
                  {keywords.map((kw) => (
                    <li key={kw}>{kw}</li>
                  ))}
                </ul>
              </li>
            ))}
          </List>
        </Content>
      </Container>
    </Section>
  );
};

export default Skills;
