import React from "react";
import { format } from "date-fns/fp";

const Tenure: React.FC<Props> = ({ startDate, endDate }) => (
  <p>
    {dateToMonthYear(startDate)} -{" "}
    {endDate ? dateToMonthYear(endDate) : "Present"}
  </p>
);

type Props = {
  startDate: string;
  endDate: string;
};

const dateToMonthYear = (dateStr: string): string =>
  format("MMM yyyy", Date.parse(dateStr));

export default Tenure;
